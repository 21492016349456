'use client'

import { useState, useCallback, useEffect } from 'react'
import { dir } from 'i18next'
import { Grid, Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import momentJalaali from 'moment-jalaali'

// Types
import type { APIMyActivityAdminItem } from '@/types/api/items/statistic/my-activity'
import type { APIStatisticMyActivityAdminResult } from '@/types/api/(statistic)/statistic/my-activity'
import type { ChartDate, ChartProps } from '@/types/components/molecules/charts'

// Helpers
import { useMyActivityStore } from '@/helpers/store/my-activity'
import { useTranslation } from '@/helpers/i18n/client'

// Utils
import ToPersianNumber from '@/utils/to-persian-number'
import Ucwords from '@/utils/ucwords'

// Components
import DayItem from '@/components/atoms/charts/day'

const UserChartMolecule = (props: ChartProps) => {
	// Props
	const { lng } = props

	// Variables
	const langDir = dir(lng)
	const { t } = useTranslation(lng)
	const [initialze, setInitialze] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const { activities, setActivities } = useMyActivityStore()
	const [days, setDays] = useState<ChartDate[]>([])
	const [maxNumber, setMaxNumber] = useState<number>(1)
	const today = new Date()
	const firstDay = lng === 'fa' ? 6 : 1
	const todayDay = today.getDay() === 0 ? 7 : today.getDay()
	const todayIndex = todayDay > firstDay - 1 ? todayDay - (firstDay - 1) : todayDay < firstDay - 1 ? (todayDay - (firstDay - 1) + 7) % 7 : 7

	// Callbacks
	const setDaysNumbers = useCallback(
		(items: APIMyActivityAdminItem[]) => {
			// Set days
			const newDays: ChartDate[] = []

			for (let i = 52 * 7 - 1 + todayIndex; i >= 0; i--) {
				const newDate = new Date()
				newDate.setDate(newDate.getDate() - i)

				newDays.push({
					date: newDate.toDateString(),
					number: 0
				})
			}

			items.map(item => {
				const date = new Date(item.created_date)
				const dayIndex = newDays.findIndex(day => day.date === date.toDateString())
				if (dayIndex !== -1) newDays[dayIndex].number += 1
			})

			// Get numbers
			const numbers: number[] = newDays.map(day => day.number).filter(number => number > 0)

			// Update
			setDays(newDays)
			setMaxNumber(Math.max(...numbers))
		},
		[todayIndex]
	)

	const getMyActivity = useCallback(async () => {
		// Enable loading
		setLoading(true)

		// Response
		const res = await fetch('/api/statistic/my-activity', {
			headers: { 'Accept-Language': lng }
		})

		// Result
		const data: APIStatisticMyActivityAdminResult = await res.json()

		// Disable loading
		setLoading(false)
		setInitialze(true)

		// Check result
		if (data.status && data.data?.items.length) {
			const items = data.data?.items
			items.sort((a, b) => new Date(a.created_date).getTime() - new Date(b.created_date).getTime())

			setActivities(items)
			setDaysNumbers(items)
		}
	}, [lng, setActivities, setDaysNumbers])

	useEffect(() => {
		if (!initialze) getMyActivity()
		if (activities.length) setDaysNumbers(activities)
	}, [initialze, activities, getMyActivity, setDaysNumbers])

	return (
		<Grid
			size={12}
			position="relative"
			bgcolor="background.paper"
			border="1px solid"
			borderColor="divider"
			borderRadius={1}
			boxShadow="0 1px 3px rgba(0, 0, 0, .025), 0 1px 2px rgba(0, 0, 0, .05)"
		>
			<Grid container justifyContent={{ xs: 'center', md: 'space-between' }} spacing={2} p={2}>
				<Grid size={{ xs: 12, md: 4, lg: 3 }}>
					<Grid container direction="column" spacing={0.5}>
						{activities
							.slice(-5)
							.reverse()
							.map((activity, index) => {
								let name = activity.section_name

								// Find locale file
								let localeFile = 'common'

								// Check store
								if (name.startsWith('store')) {
									localeFile = 'store'
									name = `store${Ucwords(name.replace('store', ''))}`
								} else if (name.startsWith('chat')) {
									localeFile = 'chat'
									name = `chat${Ucwords(name.replace('chat', ''))}`
								} else if (name.startsWith('crm')) {
									localeFile = 'crm'
									name = `crm${Ucwords(name.replace('crm', ''))}`
								} else if (name.startsWith('course')) {
									localeFile = 'course'
									name = `course${Ucwords(name.replace('course', ''))}`
								}

								return (
									<Grid size={12} key={index}>
										<Grid container justifyContent="space-between" spacing={1} width={1}>
											<Grid display="flex" size={6}>
												<Typography
													component="span"
													display="inline-block"
													maxWidth={1}
													px={1}
													fontSize={12}
													fontWeight={700}
													textOverflow="ellipsis"
													whiteSpace="nowrap"
													color="grey.800"
													bgcolor="grey.200"
													borderRadius={1}
													overflow="hidden"
												>
													{t(`common:label.${activity.action_name}`)} {t(`${localeFile}:title.${name}`)}
												</Typography>
											</Grid>

											<Grid display="flex" size={6}>
												<Typography
													component="span"
													display="inline-block"
													maxWidth={1}
													fontSize={12}
													textOverflow="ellipsis"
													whiteSpace="nowrap"
													overflow="hidden"
												>
													{lng === 'fa'
														? ToPersianNumber(momentJalaali(activity.created_date).format('jYYYY-jMM-jDD HH:mm:ss'))
														: moment(activity.created_date).format('YYYY-MM-DD HH:mm:ss')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								)
							})}
					</Grid>
				</Grid>

				<Grid size={{ xs: 12, md: 8, lg: 9, xl: 'auto' }} overflow="auto">
					<Grid container spacing={1} width={{ xs: 1, xl: 742 }}>
						<Grid size={12}>
							<Box display="inline-flex" flexDirection="column" flexWrap="wrap" width={742} height={98} sx={{ ...(langDir === 'rtl' && { direction: 'rtl' }) }}>
								{days.map((day, index) => {
									const color = day.number ? Math.ceil((day.number / (maxNumber || 1)) * 8) : 0
									const dateString = lng === 'fa' ? ToPersianNumber(momentJalaali(day.date).format('jYYYY-jMM-jDD')) : moment(day.date).format('YYYY-MM-DD')

									return (
										<Tooltip key={index} title={dateString}>
											<Box component="span" width={12} height={12} m={0.125}>
												<DayItem color={color} number={day.number} />
											</Box>
										</Tooltip>
									)
								})}
							</Box>
						</Grid>

						<Grid size={12}>
							<Grid container spacing={0.125}>
								<DayItem color={0} />
								<DayItem color={1} />
								<DayItem color={3} />
								<DayItem color={5} />
								<DayItem color={7} />
								<DayItem color={9} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{loading && (
				<Box position="absolute" top={8} right={8}>
					<CircularProgress size={20} />
				</Box>
			)}
		</Grid>
	)
}

export default UserChartMolecule
