import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

// Types
import type { MyActivityStore } from '@/types/helpers/store/my-activity'
import type { APIMyActivityAdminItem } from '@/types/api/items/statistic/my-activity'

// Configurations
import Config from '@/config'

// MyActivity Store
export const useMyActivityStore = create(
	persist<MyActivityStore>(
		set => ({
			activities: [],
			setActivities: (activities: APIMyActivityAdminItem[]) => set({ activities })
		}),
		{
			name: `${Config.shortName}-my-activity`,
			storage: createJSONStorage(() => localStorage)
		}
	)
)
