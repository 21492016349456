'use client'

import { Grid } from '@mui/material'
import { green } from '@mui/material/colors'

// Types
import type { DayProps } from '@/types/components/molecules/charts/day'

const DayItem = (props: DayProps) => {
	// Props
	const { color, number } = props

	return (
		<Grid
			width={12}
			height={12}
			border="1px solid"
			borderRadius={0.25}
			sx={theme => ({
				...(color === 0 && { bgcolor: 'grey.100', borderColor: 'grey.200' }),
				...(color === 1 && { bgcolor: green[50], borderColor: green[100] }),
				...(color === 2 && { bgcolor: green[100], borderColor: green[200] }),
				...(color === 3 && { bgcolor: green[200], borderColor: green[300] }),
				...(color === 4 && { bgcolor: green[300], borderColor: green[400] }),
				...(color === 5 && { bgcolor: green[400], borderColor: green[500] }),
				...(color === 6 && { bgcolor: green[500], borderColor: green[600] }),
				...(color === 7 && { bgcolor: green[600], borderColor: green[700] }),
				...(color === 8 && { bgcolor: green[700], borderColor: green[800] }),
				...(color === 9 && { bgcolor: green[800], borderColor: green[900] }),
				'&:hover': {
					borderColor: 'grey.800'
				},
				...theme.applyStyles('dark', {
					...(color === 0 &&
						number === 0 && {
							opacity: 0.3,
							bgcolor: 'grey.800',
							borderColor: 'grey.600'
						})
				})
			})}
		/>
	)
}

export default DayItem
